var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-colors-wrapper",class:{ active: _vm.sideBar }},[_c('div',{staticClass:"inner-container"},[_vm._m(0),_c('div',{staticClass:"colors-container"},[_c('color-picker',{attrs:{"name":"textColor","display":"Brand Titles","color":_vm.customColors.brandTitles},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'brandTitles' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"textColor","display":"Contact Info","color":_vm.customColors.contactInfo},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'contactInfo' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"textColor","display":"Button Color","color":_vm.customColors.buttonColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'buttonColor' ].concat( argsArray ))}}}),_c('diyobo-button',{staticClass:"reset-btn primary",attrs:{"txt":"Reset"},on:{"click":_vm.reset}})],1)]),_c('div',{staticClass:"side-bar",on:{"click":_vm.showSideBar}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-up"}}),_c('div',{staticClass:"text"},[_vm._v("Customize Brand Page Colors")]),_c('font-awesome-icon',{attrs:{"icon":"chevron-up"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"colors-header"},[_c('h4',[_vm._v("Customize Brand Page Colors")]),_c('span',[_vm._v("Click to select your color")])])}]

export { render, staticRenderFns }