<template>
  <div class="event">
    <div class="event-inner" v-on:click="expand()">
      <div class="event-banner" ref="event-banner" v-if="imgPref == ''">
        <img :src="defaultImg" :alt="name" />
        <img
          v-if="cancelled"
          class="cancelled"
          src="/img/cancelled.png"
          alt="cancelled"
        />
      </div>
      <div class="event-banner" ref="event-banner" v-if="imgPref != ''">
        <img :src="dashIcon" :alt="name" />
        <img
          v-if="cancelled"
          class="cancelled"
          src="/img/cancelled.png"
          alt="cancelled"
        />
      </div>
      <div class="event-title">{{ name }}</div>
      <div class="event-date">{{ formattedDate }}</div>
      <div class="event-details">
        <div class="event-details-inner" :class="{ active: expanded }">
          <div class="detail">Event: {{ name }}</div>
          <div class="detail">Venue: {{ venue }}</div>
          <div class="detail" :pricing="pricing">
            Tickets starting at ${{ pricing }}
          </div>
        </div>
      </div>
      <div class="event-details-toggle" :class="{ active: expanded }">
        <font-awesome-icon icon="angle-down" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.event {
  flex-grow: unset;
  margin: 1em 0.7533%;
  padding: 6px;
  position: relative;
  width: calc(31.5% - 11px);
  background-color: var(--event-background);
  border: 1px solid var(--event-border);
  border-radius: 4px;
  // box-shadow: 0 0 6px 2px #00000070;
  box-shadow: var(--event-boxshadow);
  overflow: hidden;

  @media screen and (max-width: 971px) {
    margin: 1em 0.75%;
    width: calc(48.5% - 14px);
  }

  @media screen and (max-width: 724px) {
    margin: 1em 1%;
    width: 98%;
  }

  .event-inner {
    .event-banner {
      width: 100%;
      cursor: pointer;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.4s;

        &.cancelled {
          position: absolute;
          height: 240px;
          right: 2px;
          top: -20px;
        }
      }
    } // end event-banner

    .event-title {
      margin: 0.25em 0;
      font-family: inherit;
      font-size: 1.18em;
      font-weight: 700;
      letter-spacing: -0.05em;
    } // end event-title

    .event-date {
      font-family: inherit;
      color: #737373;
    } // end event-date

    .event-details {
      margin: 0 -6px;

      .event-details-inner {
        display: flex;
        flex-wrap: wrap;
        max-height: 0;
        overflow: hidden;
        transition: 0.4s;

        &.active {
          max-height: 1000px;
        }

        .detail {
          width: 90%;
          margin-left: 5px;
          text-transform: capitalize;
          color: #737373;
          border-radius: 4px;
        }

        a.detail {
          position: relative;
          z-index: 1;
          width: 46.5%;
          color: inherit;
          background-color: #222222;
          background: linear-gradient(#222222, #1a1a1a);
          border: 1px solid #363636;
          overflow: hidden;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 101%;
            height: 100%;
            background: linear-gradient(#353535, #202020);
            opacity: 0;
            transition: 0.4s;
          }

          &:hover::before {
            opacity: 1;
          }
        }
      } // end event-details inner
    } // end event-details

    .event-details-toggle {
      position: relative;
      top: -1em;
      z-index: 3;
      left: 80%;
      width: 20%;
      max-height: 0px;
      text-align: right;
      color: #666666;
      font-size: 1.25em;
      cursor: pointer;
      transition: 0.4s;

      &.active {
        top: 0;
        max-height: 20px;

        svg {
          transform: rotateX(180deg);
        }
      }
    } // end event-details-toggle
  } // end event-inner
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";

library.add(faAngleDown);

export default {
  name: "diyobo-past-event",
  components: {
    FontAwesomeIcon,
  },
  props: {
    name: String,
    date: Object,
    image: String,
    venue: String,
    url: String,
    oldImage: String,
    event: Object,
    cancelled: Boolean,
  },
  data() {
    return {
      expanded: false,
      timeout: null,
      months: [
        "January",
        "Feburary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      imgPref: "",
      dashIcon: this.image
        ? process.env.VUE_APP_IMAGE_URL + this.image.hero
        : "",
      defaultImg: process.env.VUE_APP_IMAGE_URL + "media/img/venue/default.png",
    };
  },
  computed: {
    formattedDate() {
      return dateFormat(+this.date.$date.$numberLong, "ddd, mmm dS, yyyy");
    },
    pricing() {
      let priceToDisplay = 0;
      this.event.tiers.forEach((ele) => {
        let priceToCompare = ele.price + ele.ccfee + ele.dfee + ele.addfee;
        if (priceToCompare > priceToDisplay) {
          priceToDisplay = priceToCompare;
        }
      });
      return priceToDisplay.toFixed(2);
    },
  },
  mounted() {
    this.imgPref = this.dashIcon;
    this.$refs["event-banner"].style.height =
      this.$refs["event-banner"].clientWidth / 2 + "px";
  },
  methods: {
    expand() {
      console.log("wtf");
      if (this.expanded) this.expanded = false;
      else this.expanded = true;
    },
  },
};
</script>
