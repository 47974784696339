<template>
  <Card>
    <div v-if="link" class="page-link">
      <div class="page-link-inner">
        <div class="copy-button" v-on:click="copyPageLink">
          <font-awesome-icon icon="copy" />

          Click to Copy
        </div>
        <div class="link">
          <p>{{ link }}</p>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faAngleDown);
import { saveToClipboard } from "@/helpers/clipboard";

export default {
  name: "copy-link",

  components: {
    FontAwesomeIcon
  },
  props: {
    link: String
  },
  methods: {
    copyPageLink() {
      saveToClipboard(this.link, this.$root.$refs.alert);
      this.$toast.success("Brand link copied to clipboard.");
    }
  }
};
</script>

<style lang="less" scoped>
.card {
  padding: 5px 8px;
  margin-top: 32px;
}
.copy-button {
  background: var(--tab-bg);
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 16px;
  cursor: pointer;
}
.page-link-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  h2 {
    color: whitesmoke;
  }
}
</style>
