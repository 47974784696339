<template>
  <div class="company-page" :style="customBrandColors">
    <screen-slider-modal
      :sliderMsg="screenSliderMsg"
      v-on:dismiss="dismiss('sliderModal')"
      v-if="sliderModal"
    />

    <loader site="buyers" :active="loading" />
    <div id="Content" v-if="!loading">
      <brand-colors
        @changeColor="onChangeColor"
        :defaultColors="customColors"
      />

      <div class="shadow"></div>
      <!-- <div class="header-shadow" :style="headerStyleObj"></div>
      <div class="content-shadow" :style="bodyStyleObj"></div> -->
      <div class="content-inner">
        <copy-link :link="link" />

        <div class="brand-images">
          <div class="header-img-container">
            <uploader
              v-model="showUploader"
              :width="1100"
              :height="619"
              no-circle
              langType="en"
              @crop-success="onImageSelected(arguments[0], $event)"
            />
            <image-uploader
              v-model="showProfileUploader"
              :width="500"
              :height="500"
              no-circle
              langType="en"
              @crop-success="onImageSelected(arguments[0], $event)"
            />

            <div class="mask" @click="openCropper('header')">
              <div class="text">Update Header Picture</div>
            </div>
            <div
              class="file-upload-wrapper"
              v-on:click="smallImg($event, 'header')"
            >
              <div class="file-upload-container" id="file-container-1">
                <input
                  type="file"
                  name="file"
                  id="small-file-1"
                  class="img-upload"
                />
              </div>
            </div>
            <div class="header-img">
              <img id="small-image-1" class="small-image" :src="headerImage" />
            </div>
          </div>
          <div class="main-info-container">
            <div class="profile-info-container">
              <div class="profile-image">
                <div class="small-img">
                  <div class="mask" v-on:click="openCropper('profile')">
                    <div class="text">Update Profile Picture</div>
                  </div>
                  <div
                    class="file-upload-wrapper"
                    v-on:click="smallImg($event, 'profile')"
                  >
                    <div class="file-upload-container" id="file-container-2">
                      <input
                        type="file"
                        name="file"
                        id="small-file-2"
                        class="img-upload"
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>

                  <div class="profile-img">
                    <img
                      id="small-image-2"
                      class="small-image"
                      :src="profileImage"
                    />
                  </div>
                </div>
              </div>
              <div class="main-info">
                <div class="left-info">
                  <h2>{{ title }}</h2>
                  <p class="address">
                    {{ address }}
                  </p>
                  <p>{{ address2 }}</p>
                  <p class="phone">
                    <a :href="`tel:${phoneNum}`">
                      {{ phoneNum }}
                    </a>
                  </p>
                </div>
                <div class="right-info">
                  <a
                    class="website more-info-button"
                    v-if="websiteUrl"
                    :href="websiteUrl"
                    target="_blank"
                  >
                    <strong>Website</strong>
                  </a>
                  <a
                    class="more-info-button email"
                    :href="`mailto:${email}`"
                    target="_blank"
                  >
                    <strong>Email</strong>
                  </a>
                </div>

                <!-- <p class="phone"></p> -->
              </div>
            </div>
          </div>
        </div>

        <div class="divider"></div>
        <div class="current-events-wrapper background-wrapper">
          <!-- <button id="btn-page" class="color-btn background-btn">
            <span>Background</span>
            <div class="btn-color-display" :style="bodyStyleObj"></div>
          </button> -->
          <div class="current-events-label label-container">
            <h2>{{ companyInfo.companyName }} Events</h2>
            <div class="input-container" v-if="false">
              <diyobo-input
                type="dropdown"
                label="Arrange By"
                :placeholder="'↓ Date'"
                :options="arrangementOptions"
                v-model="currentEventArrangement"
                :val="currentEventArrangement"
                :labelStyle="headerTextStyleObj"
                ref="current"
              />
            </div>
          </div>
          <div class="events-wrapper">
            <diyobo-event
              v-for="(event, index) in currentEventsToUse"
              :key="index"
              :name="event.name"
              :image="event.images"
              :oldImage="`${service_url}${event.image}`"
              :date="event.start"
              :venue="event.venue"
              :url="event.url"
              :event="event"
              :tiers="event.tiers"
              :cancelled="event.cancelled"
            />
          </div>
          <div v-if="!currentEventsToUse.length" style="margin-left: 0.7533%">
            No Current Events
          </div>
          <div class="divider"></div>
          <div v-for="(coBrand, index) in coBrandList" :key="index">
            <div class="event-header-container">
              <h2 class="event-header-title">
                {{ coBrand.companyName }} Events
              </h2>
              <!-- checkbox here -->
              <FormulateInput
                class="event-header-check-box"
                v-model="coBrand.showBrandPage"
                type="checkbox"
                label="Show Events to Buyers"
                @input="updatePermissionBrandPage(index)"
              />
            </div>

            <div class="events-wrapper">
              <diyobo-event
                v-for="(event, index) in coBrand.eventList"
                :key="index"
                :name="event.name"
                :image="event.images"
                :oldImage="`${service_url}${event.image}`"
                :date="event.start"
                :venue="event.venue"
                :url="event.url"
                :event="event"
                :tiers="event.tiers"
                :cancelled="event.cancelled"
              />
            </div>
            <div class="divider"></div>
          </div>
        </div>

        <div class="past-events-wrapper background-wrapper">
          <div class="past-events-label label-container">
            <div class="title">
              <h2>Past Events</h2>
              <diyobo-checkbox
                class="hide-past"
                label="Check if you don't want to display your past events."
                v-model="hidePast"
                :checked="hidePast"
                v-on:input="onToggleHidePast"
              />
            </div>
            <div class="input-container" v-if="false">
              <diyobo-input
                type="dropdown"
                label="Arrange By"
                :placeholder="'↓ Date'"
                :options="arrangementOptions"
                v-model="pastEventArrangement"
                :val="pastEventArrangement"
                :labelStyle="headerTextStyleObj"
                ref="current"
              />
            </div>
          </div>
          <div class="events-wrapper" v-if="!hidePast">
            <past-diyobo-event
              v-for="(event, index) in pastEventsToUse"
              :key="index"
              :name="event.name"
              :image="event.images"
              :oldImage="`${service_url}${event.image}`"
              :date="event.start"
              :venue="event.venue"
              :url="event.url"
              :event="event"
              :cancelled="event.cancelled"
            />
            <div v-if="!pastEventsToUse.length" style="margin-left: 0.7533%">
              No Past Events
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="continue-bar" v-if="isSetup">
      <div class="row">
        <div class="col full flex">
          <p>
            <strong>Mandatory:</strong>
            Update Cover & Brand Photo
            <strong>Optional:</strong>
            Change Brand Colors
          </p>
          <!-- <p>Once you're happy with the page design, please continue.</p> -->
          <diyobo-button
            txt="Continue"
            class="primary"
            :disabled="brandInfo < 1"
            @click="goToPayout"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
#Content {
  padding-top: 32px;
  background: var(--custom-brand-pageBG-color);
}
.main-cont.stepper {
  margin-top: 115px;
}
//page level css
.divider {
  margin: 1em auto !important;
}

h1,
h2,
h3 {
  color: var(--custom-brand-brandTitles);
}

.company-page {
  .page-link {
    display: flex;
    position: relative;
    justify-content: center;
    border-radius: 8px;
    max-width: 1100px;
    margin: 0 auto;
    top: 0;
    transform: scale(0.8);

    .page-link-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 10px 0px;
      h2 {
        color: whitesmoke;
      }
    }
  }
  .header-shadow {
    position: absolute;
    width: 100%;
    height: 500px;
    z-index: 0;
  }

  .content-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .image-wrapper {
    position: relative;
    .header-shadow {
      position: absolute;
    }

    .image-container {
      position: relative;
      height: 300px;
      // width: 94%;
      max-width: 1100px;
      margin: auto;
    }
    //end image-container
    .header-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .profile-container {
      width: 56%;
      position: absolute;
      height: 200px;
      right: 0px;
      bottom: -115px;
      .profile-img {
        position: relative;
        width: 20%;
        height: 70%;
        top: 0px;
      }
    }
  }

  .brand-images {
    position: relative;
    margin-top: 32px;
    margin-bottom: 124px;
    @media screen and (max-width: 750px) {
      margin-bottom: 179px;
    }
    @media screen and (max-width: 610px) {
      margin-bottom: 150px;
    }
    @media screen and (max-width: 480px) {
      margin-bottom: 204px;
    }
  }
  .header-img-container {
    position: relative;
    width: 100%;
    max-width: 1100px;
    margin: auto;
    margin-bottom: 40px;
    border-radius: 16px;
  }
  &::v-deep .vue-image-crop-upload {
    .vicp-wrap {
      width: 80%;
      max-width: 600px;
      background-color: var(--event-background);
      border-radius: 8px;
      padding: 20px;
      height: fit-content;

      .vicp-crop {
        display: flex;
        flex-wrap: wrap;
        width: 96%;
        justify-content: space-between;
        // align-items: center;
      }
      .vicp-operate a {
        width: initial;
        padding: 0 15px;
      }
      .vicp-preview {
        .vicp-preview-item {
          span {
            bottom: 0;
          }
        }
      }
      .vicp-crop-left,
      .vicp-crop-right {
        float: initial;
      }
      .vicp-drop-area {
        background-color: @colors[content-background];
        padding-left: 0;
        padding-right: 0;
        border-radius: 8px;

        .vicp-icon1 {
          filter: var(--uploader-filter);
        }

        .vicp-hint {
          color: @colors[text];
          @media screen and (max-width: 375px) {
            font-size: 10px;
          }
        }
      }
    }
    @media screen and (max-width: 600px) {
      &::v-deep .vue-image-crop-upload {
        .vicp-wrap {
          .vicp-crop-left,
          .vicp-crop-right {
            float: initial;
          }
        }
      }
    }
  }
  .mask {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #41464f;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: 0.5s;
    text-align: center;
    border-radius: inherit;
    &:hover {
      display: flex;
      opacity: 0.9;
      transition: 0.5s;
      justify-content: center;
    }
  }
  .file-upload-wrapper {
    border-radius: inherit;
    overflow: hidden;
    background-color: #3e3e3e;
    cursor: pointer;

    .small-image {
      width: 100%;
      // height: 300px;
      object-fit: cover;
    }

    // end small-image

    .file-upload-container {
      display: none;
      position: absolute;
      display: flex;
      justify-content: center;
      margin: auto;
      width: 0%;
      height: 0%;
      cursor: pointer;

      .img-upload {
        display: none;
      }
      // end img-upload

      .img-upload + label {
        display: flex;
        justify-content: center;
        color: #5b5b5b;
        align-items: center;
        font-size: 12px;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      // end img-upload + label
    }
    // end file-upload-container
  }

  .header-img {
    display: flex;
    // width: 100%;
    aspect-ratio: 16/9;
    border: 1px solid var(--border);
    overflow: hidden;
    border-radius: inherit;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  // end file-upload-wrapper

  //end image-wrapper

  // main-info-container
  .main-info-container {
    position: absolute;
    left: 25px;
    bottom: -95px;
    width: 95%;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;

    .profile-info-container {
      display: flex;
      width: 100%;

      .profile-image {
        .small-img {
          position: relative;
          width: 140px;
          height: 140px;
          border: solid 1px #424242;
          background: var(--dashboard-tiles);
          border-radius: 10px;

          .small-image {
            height: 100%;
          }

          .mask {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #41464f;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: 0.5s;
            text-align: center;
            border-radius: inherit;

            &:hover {
              display: flex;
              opacity: 0.9;
              transition: 0.5s;
              justify-content: center;
            }
          }
          .file-upload-wrapper {
            border-radius: inherit;
            overflow: hidden;
            cursor: pointer;

            .small-image {
              width: 100%;

              // height: 300px;
              object-fit: cover;
            }

            // end small-image

            .file-upload-container {
              display: none;
              position: absolute;
              display: flex;
              justify-content: center;
              margin: auto;
              width: 0%;
              height: 0%;
              cursor: pointer;

              .img-upload {
                display: none;
              }
              // end img-upload

              .img-upload + label {
                display: flex;
                justify-content: center;
                color: #5b5b5b;
                align-items: center;
                font-size: 12px;
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }

          .profile-img {
            height: 100%;
            width: 100%;
            border-radius: inherit;

            img {
              border-radius: inherit;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .main-info {
        margin: 0 0 0 16px;
        display: flex;
        justify-content: space-between;
        height: 62%;
        margin-top: auto;
        flex: 1;

        .left-info {
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          h2 {
            color: var(--custom-brand-brandTitles);
          }

          p.address,
          p.phone a {
            color: var(--custom-brand-contactInfo) !important;
          }
          h2,
          p {
            margin: 0;
          }
        }
        .right-info {
          display: flex;
          .more-info-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            overflow: hidden;
            margin: 3px;
            height: 30px;
            padding: 5px 18px;
            background: var(--custom-brand-buttonColor);
            border-radius: 6px;
            color: var(--custom-brand-brandTitles);
          }
          // margin: 0 16px;
          // display: flex;
          // flex-direction: column;
          // justify-content: space-around;
          // height: 67%;
          // margin-top: auto;
        }
      }
    }
    @media screen and (max-width: 750px) {
      bottom: -158px;
      .profile-info-container {
        flex-wrap: wrap;
        justify-content: center;
        .main-info {
          width: 100%;
          flex: initial;
          height: 18%;

          .left-info {
            h2 {
              font-size: 18px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 610px) {
      bottom: -120px;
      left: initial;
      width: 100%;
      .profile-info-container {
        .profile-image {
          .small-img {
            width: 100px;
            height: 100px;
          }
        }
        .main-info {
          .right-info {
            .more-info-button {
              padding: 5px 16;
            }
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      bottom: -180px;

      .profile-info-container {
        .main-info {
          display: initial;
          margin: initial;
          height: 42%;
          .left-info {
            align-items: center;
            h2 {
              margin: 4px 0 2px 0;
            }
            p:last-child {
              margin-bottom: 4px;
            }
          }
          .right-info {
            justify-content: center;
            .more-info-button {
              padding: 5px 0;
            }
          }
        }
      }
    }
    @media screen and (max-width: 400px) {
    }
  }

  .content-inner {
    padding-top: 0px !important;
    margin-top: -32px !important;
    .background-wrapper {
      margin-top: 0 !important;
    }

    .events-wrapper {
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin-left: -0.7533%;
      // padding: 2em 0;
      width: 101.5066%;
    }
    .event-header-container {
      display: flex;
      align-items: center;

      .event-header-title {
        width: 400px;
      }
      .event-header-check-box {
        margin: 0;
      }
    }
    .past-events-wrapper {
      margin-top: 40px;
    }

    .title {
      display: flex;
      align-items: center;

      .input-wrapper {
        margin: 0 16px;
      }
    }

    .label-container {
      font-size: 15px;
      font-weight: bold;
      justify-content: space-between;
      display: flex;
    }

    .input-container {
      width: 200px;
    }

    .info-wrapper {
      text-align: center;
      margin-top: 100px;
      position: relative;
      .info-title {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      //end info-title
      .info-text {
        margin: 5px 15px;
      }
      .info-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
      }
      //end info-text
    }
    //end info-wrapper

    .color-btn {
      width: 120px;
      border-radius: 8px;
      background-color: #4cac55;
      background: linear-gradient(#4cac55, #39793f);
      color: #fff;
      border-color: #5fb469;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      span {
        width: 70px;
        text-align: center;
      }

      div {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        background-color: #222222;
      }
    }
    .inactive {
      display: none;
    }

    .header-btn {
      position: absolute;
      left: 20px;
      top: -122px;
    }

    .header-text-btn {
      position: absolute;
      left: 20px;
      top: -72px;
    }

    .contact-text-btn {
      position: absolute;
      left: 20px;
      top: -16px;
    }
  }

  .continue-bar {
    display: flex;
    position: fixed;
    width: 100vw;
    max-width: 1100px;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border: 1px solid var(--dashboard-border);
    border-radius: 8px;
    background-image: var(--dashboard-tiles);
    box-shadow: var(--dashboard-shadow);
    // position: relative;
    z-index: 100;

    .row {
      display: flex;
      flex-wrap: wrap;

      // margin-left: -0.5em;
      // width: calc(100% + 1em);
      width: 100%;
      justify-content: space-between;

      .col {
        p {
          font-size: 16px;
        }

        &.flex {
          display: flex;
          justify-content: space-between;
        }
        &.center {
          justify-content: center;
        }
        &.full {
          width: 100%;
        }
        // end full col

        &.half {
          width: calc(50% - 10px);
        }
        // end half col

        &.quarter {
          width: calc(25% - 10px);
        }
        // end 1/4 col

        &.third {
          width: calc(33.33% - 10px);
        }

        &.quarterx3 {
          width: calc(75% - 10px);
        }
        // end 3/4 col

        &.eighth {
          width: calc(12.5% - 10px);
        }
      }
      // end col
    }
    @media screen and (max-width: 1140px) {
      margin: 0 20px;
      width: calc(100% - 60px);
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;

      .right {
        margin-top: 20px;
      }
    }
  }
}
</style>

<script>
import Vue from "vue";
import PastDiyoboEvent from "../components/events/PastDiyoboEvent.vue";
import DiyoboEvent from "../components/events/DiyoboEvent.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import dateFormat from "dateformat";
import Picker from "vanilla-picker";
import ScreenSliderModal from "../components/modals/ScreenSliderModal.vue";
import { saveToClipboard } from "@/helpers/clipboard";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import debounce from "debounce";

import Uploader from "vue-image-crop-upload/upload-2.vue";
import ImageUploader from "vue-image-crop-upload/upload-2.vue";

import ModalContent from "@/helpers/modals";
import { getSessionCookie } from "../helpers/auth";
import Loader from "@/components/Loader.vue";
import CopyLink from "../components/brand/CopyLink";
import ColorPicker from "@/components/ColorPicker.vue";
import BrandColors from "../components/brand/BrandColors.vue";

library.add(faCopy);

export default {
  name: "company-page",
  components: {
    DiyoboEvent,
    PastDiyoboEvent,
    DiyoboInput,
    DiyoboCheckbox,
    ScreenSliderModal,
    FontAwesomeIcon,
    ImageUploader,
    Uploader,
    Loader,
    DiyoboButton,
    CopyLink,
    ColorPicker,
    BrandColors,
    ImageUploader
  },
  head() {
    return {
      title: this.title ? `${this.title}'s Events` : ""
    };
  },
  props: {
    info: Object
  },
  data() {
    return {
      currentEvents: [],
      coBrandList: [],
      loading: false,
      bus: new Vue(),
      showUploader: false,
      showProfileUploader: false,
      pastEvents: [],
      companyInfo: {},
      pageInfo: {},
      arrangementOptions: [
        { label: `↑ Date`, value: "upDate" },
        { label: `↓ Date`, value: "downDate" },
        { label: `↑ Price`, value: "upPrice" },
        { label: `↓ Price`, value: "downPrice" },
        { label: `Venue`, value: "venue" }
      ],
      currentEventArrangement: "downDate",
      pastEventArrangement: "downDate",
      title: "",
      address: "",
      address2: "",
      phoneNum: "",
      website: "",
      email: "",
      headerImage: "",
      profileImage: "",
      service_url: "",
      subdomain: "",
      secretVenue: false,
      customColors: {
        pageBG: "var(--content-background)",
        brandTitles: "var(--text)",
        contactInfo: "var(--text)",
        buttonColor: "var(--tab-bg)"
      },
      bodyStyleObj: {},
      headerStyleObj: {},
      headerTextStyleObj: {},
      textBtnStyleObj: {},
      contactBtnStyleObj: {},
      contactTextStyleObj: {},
      companyCoordinator: false,
      headerImgToSendToDb: "",
      profileImgToSendToDb: "",
      fileName: "",
      fileType: "",
      sliderModal: false,
      screenSliderMsg: "",
      sliderModalMiddleMan: false,
      link: null,
      hidePast: false,

      // used for image cropper
      cropperType: "",
      cropperWidth: null,
      cropperHeight: null
    };
  },
  watch: {
    companyInfo(val) {
      this.title = val.companyName;
      this.address = val.companyAddress;
      this.address2 = val.city + ", " + val.regionName + " " + val.zipCode;
      this.phoneNum = val.companyPhone;
      this.website = val.companyWebsite || "";
      this.email = val.companyEmail;
    },
    headerImgToSendToDb(image) {
      fetch(image)
        .then(res => res.blob())
        .then(blob => {
          const data = new FormData();

          data.append("type", "header");
          data.append(
            "image",
            new File([blob], "header.png", {
              type: "image/png"
            })
          );
          let subdomain = this.$route.params.subdomain;

          data.append("brandUrl", subdomain);
          this.$axios.post("/company/save-page-image", data).then(response => {
            this.$toast.success("Header picture updated");
          });
        });
    },
    profileImgToSendToDb(image) {
      fetch(image)
        .then(res => res.blob())
        .then(blob => {
          const data = new FormData();

          data.append("type", "profile");
          data.append(
            "image",
            new File([blob], "profile.png", {
              type: "image/png"
            })
          );
          let subdomain = this.$route.params.subdomain;

          data.append("brandUrl", subdomain);
          this.$axios.post("/company/save-page-image", data).then(response => {
            if (this.$store.state.user.plannerPage === 0) {
              this.$store.commit("updateUserProperty", {
                property: "plannerPage",
                value: 1
              });

              // setTimeout(() => {
              //   let subdomain = this.subdomain;
              //   this.$router.push(`/brands/payout/${subdomain}`);
              // }, 4000);
            }
            this.$toast.success("Profile picture updated");
          });
        });
    },
    sliderModalMiddleMan(val) {
      if (val) {
        setTimeout(() => {
          this.sliderModal = false;
        }, 5000);
        this.sliderModal = true;
      }
    }
  },
  computed: {
    cropperHint() {
      return {
        hint: `Click Here (min size: ${this.cropperWidth} x ${this.cropperHeight})`
      };
    },
    customBrandColors() {
      return {
        "--custom-brand-pageBG-color": this.customColors.pageBG,
        "--custom-brand-brandTitles": this.customColors.brandTitles,
        "--custom-brand-contactInfo": this.customColors.contactInfo,
        "--custom-brand-buttonColor": this.customColors.buttonColor
      };
    },
    brandInfo() {
      return this.$store.state.user.plannerPage;
    },
    userEmail() {
      return this.$store.state.user.email;
    },
    currentEventsToUse() {
      let currentEvents = [];
      return this.currentEvents;
      if (this.currentEventArrangement == "upDate") {
        return this.currentEvents.sort((firstEle, secondEle) => {
          let firstStart = new Date(firstEle.start);
          let secondStart = new Date(secondEle.start);
          if (firstStart < secondStart) {
            return -1;
          } else {
            return 1;
          }
        });
      } else if (this.currentEventArrangement == "downDate") {
        return this.currentEvents.sort((firstEle, secondEle) => {
          let firstStart = new Date(firstEle.start);
          let secondStart = new Date(secondEle.start);
          if (firstStart < secondStart) {
            return 1;
          } else if (secondStart == firstStart) {
            return 0;
          } else {
            return -1;
          }
        });
      } else if (this.currentEventArrangement == "upPrice") {
        return this.currentEvents.sort((firstEle, secondEle) => {
          let firstStart = 0;
          firstEle.tiers.forEach(ele => {
            let priceToCompare = ele.price + ele.ccfee + ele.dfee + ele.addfee;
            if (priceToCompare > firstStart) {
              firstStart = priceToCompare;
            }
          });
          let secondStart = 0;
          secondEle.tiers.forEach(ele => {
            let priceToCompare = ele.price + ele.ccfee + ele.dfee + ele.addfee;
            if (priceToCompare > secondStart) {
              secondStart = priceToCompare;
            }
          });
          if (firstStart > secondStart) {
            return 1;
          } else if (secondStart == firstStart) {
            return 0;
          } else {
            return -1;
          }
        });
      } else if (this.currentEventArrangement == "downPrice") {
        return this.currentEvents.sort((firstEle, secondEle) => {
          let firstStart = 0;
          firstEle.tiers.forEach(ele => {
            let priceToCompare = ele.price + ele.ccfee + ele.dfee + ele.addfee;
            if (priceToCompare > firstStart) {
              firstStart = priceToCompare;
            }
          });
          let secondStart = 0;
          secondEle.tiers.forEach(ele => {
            let priceToCompare = ele.price + ele.ccfee + ele.dfee + ele.addfee;
            if (priceToCompare > secondStart) {
              secondStart = priceToCompare;
            }
          });
          if (firstStart < secondStart) {
            return 1;
          } else if (secondStart == firstStart) {
            return 0;
          } else {
            return -1;
          }
        });
      } else if (this.currentEventArrangement == "venue") {
        return this.currentEvents.sort((firstEle, secondEle) => {
          let firstStart = firstEle.venue;
          let secondStart = secondEle.venue;
          if (firstStart != secondStart) {
            return 1;
          } else if (secondStart == firstStart) {
            return 0;
          } else {
            return -1;
          }
        });
      }
    },
    pastEventsToUse() {
      let pastEvents = [];
      return this.pastEvents;
      if (this.pastEventArrangement == "upDate") {
        return this.pastEvents.sort((firstEle, secondEle) => {
          let firstStart = new Date(firstEle.start);
          let secondStart = new Date(secondEle.start);
          if (firstStart < secondStart) {
            return -1;
          } else {
            return 1;
          }
        });
      } else if (this.pastEventArrangement == "downDate") {
        return this.pastEvents.sort((firstEle, secondEle) => {
          let firstStart = new Date(firstEle.start);
          let secondStart = new Date(secondEle.start);
          if (firstStart < secondStart) {
            return 1;
          } else if (secondStart == firstStart) {
            return 0;
          } else {
            return -1;
          }
        });
      } else if (this.pastEventArrangement == "upPrice") {
        return this.pastEvents.sort((firstEle, secondEle) => {
          let firstStart = 0;
          firstEle.tiers.forEach(ele => {
            let priceToCompare = ele.price + ele.ccfee + ele.dfee + ele.addfee;
            if (priceToCompare > firstStart) {
              firstStart = priceToCompare;
            }
          });
          let secondStart = 0;
          secondEle.tiers.forEach(ele => {
            let priceToCompare = ele.price + ele.ccfee + ele.dfee + ele.addfee;
            if (priceToCompare > secondStart) {
              secondStart = priceToCompare;
            }
          });
          if (firstStart > secondStart) {
            return 1;
          } else if (secondStart == firstStart) {
            return 0;
          } else {
            return -1;
          }
        });
      } else if (this.pastEventArrangement == "downPrice") {
        return this.pastEvents.sort((firstEle, secondEle) => {
          let firstStart = 0;
          firstEle.tiers.forEach(ele => {
            let priceToCompare = ele.price + ele.ccfee + ele.dfee + ele.addfee;
            if (priceToCompare > firstStart) {
              firstStart = priceToCompare;
            }
          });
          let secondStart = 0;
          secondEle.tiers.forEach(ele => {
            let priceToCompare = ele.price + ele.ccfee + ele.dfee + ele.addfee;
            if (priceToCompare > secondStart) {
              secondStart = priceToCompare;
            }
          });
          if (firstStart < secondStart) {
            return 1;
          } else if (secondStart == firstStart) {
            return 0;
          } else {
            return -1;
          }
        });
      } else if (this.pastEventArrangement == "venue") {
        return this.currentEvents.sort((firstEle, secondEle) => {
          let firstStart = firstEle.venue;
          let secondStart = secondEle.venue;
          if (firstStart != secondStart) {
            return 1;
          } else if (secondStart == firstStart) {
            return 0;
          } else {
            return -1;
          }
        });
      }
    },
    websiteUrl() {
      return this.website.startsWith("http")
        ? this.website
        : "http://" + this.website;
    },
    isSetup() {
      if (this.$route.query.setup) {
        return true;
      }
    }
  },
  created() {
    this.getBrandInfo();
  },

  methods: {
    async updatePermissionBrandPage(index) {
      await this.$axios.post("/permissions/user/show-brand-page", {
        permissionId: this.coBrandList[index].permissionId,
        showBrandPage: this.coBrandList[index].showBrandPage
      });
    },
    hasStockImages() {
      if (
        this.headerImage === "/img/placeholders/default-texture.jpeg" ||
        this.profileImage === "/img/placeholders/default-profile.png" ||
        (this.headerImage === "/img/placeholders/default-texture.jpeg" &&
          this.profileImage === "/img/placeholders/default-profile.png")
      ) {
        return true;
      }
    },
    onChangeColor: debounce(function(val) {
      this.customColors = val;
      let subdomain = this.$route.params.subdomain;
      this.$axios
        .post("/company/company-page-color", { url: subdomain, colors: val })
        .then(response => {});
    }, 1000),
    goToPayout() {
      let subdomain = this.$route.params.subdomain;
      sessionStorage.brandCompleted = true;
      let noOfBrands = this.$store.state.user.plannerBrands;

      if (noOfBrands === 1) {
        this.$router.push(`/brands/payout/${subdomain}?setup=true`);
      } else {
        this.$router.push("/brands");
      }
    },
    updatePlannerPage() {
      this.$axios
        .post("/company/update-page", { subdomain: subdomain })
        .then(response => {});
    },
    getBrandInfo() {
      // get current subdomain from route
      let subdomain = this.$route.params.subdomain;

      //let session load for unknown reason
      let session = getSessionCookie();
      this.loading = true;
      if (subdomain && session) {
        this.$axios
          .post("/company/page", { subdomain: subdomain })
          .then(response => {
            let data;
            let events;
            let companyObj;
            if (response.status == 200) {
              data = response.data;
              this.coBrandList = data.coBrandList;
              events = data["0"];
              companyObj = data["company"];
              this.loading = false;

              if (data) {
                if (events["current"] && events["past"]) {
                  this.currentEvents = events["current"];
                  this.pastEvents = events["past"];
                } else {
                  this.$store.state.bus.$emit(
                    "alert",
                    ModalContent.companyFailedToRetrieveEvents
                  );
                }
                if (companyObj["pageInfo"] && companyObj["companyInfo"]) {
                  this.companyInfo = companyObj["companyInfo"];
                  const val = (this.pageInfo = companyObj["pageInfo"]);

                  this.headerImage = val.websiteImage
                    ? process.env.VUE_APP_IMAGE_URL + val.websiteImage.hero
                    : "/img/placeholders/default-texture.jpeg";

                  // if (val.profileImage.hero)
                  //   val.profileImage = val.profileImage.hero;

                  this.profileImage = val.profileImage
                    ? process.env.VUE_APP_IMAGE_URL + val.profileImage
                    : "/img/placeholders/default-profile.png";

                  if (val.customColors) {
                    this.customColors = val.customColors;
                  }
                  this.subdomain = val.subdomain;
                  this.hidePast = val.hidePast;

                  switch (process.env.VUE_APP_ENVIRONMENT) {
                    case "development":
                      this.link = `http://localhost:3100/brand/${val.subdomain}`;
                      break;
                    case "staging":
                      this.link = `https://staging.incredevent.com/brand/${val.subdomain}`;
                      break;
                    default:
                      this.link = `https://incredevent.com/brand/${val.subdomain}`;
                      break;
                  }
                  if (data["userMatch"]) {
                    this.companyCoordinator = true;
                    this.coordinatorEmail = data["userMatch"];

                    setTimeout(() => {
                      this.applyPickerToButtons();
                    }, 2000);
                  }
                } else {
                  this.$store.state.bus.$emit(
                    "alert",
                    ModalContent.companyFailedToRetrieveInfo
                  );
                }
              } else {
                this.$store.state.bus.$emit(
                  "alert",
                  ModalContent.companyInvalidUrl
                );
              }
            }
          });
      }
    },
    openCropper(type) {
      this.cropperType = type;

      switch (type) {
        case "header":
          this.cropperWidth = 1100;
          this.cropperHeight = 619;
          this.showUploader = true;
          break;
        case "profile":
          this.cropperWidth = 500;
          this.cropperHeight = 500;
          this.showProfileUploader = true;
        default:
          break;
      }

      this.$forceUpdate();
    },

    onImageSelected(image, event) {
      switch (this.cropperType) {
        case "header":
          this.headerImage = image;
          break;
        case "profile":
          this.profileImage = image;
          break;

        default:
          break;
      }

      this.smallImg(image, this.cropperType);
    },

    applyPickerToButtons() {
      let self = this;
      if (!this.loading) {
        let subdomain = this.$route.params.subdomain;

        let headerBtn = document.getElementById("btn-header");
        let textBtn = document.querySelector("#btn-text");
        let pageBtn = document.querySelector("#btn-page");
        let contactBtn = document.querySelector("#btn-contact");

        let picker1 = new Picker({
          parent: headerBtn,
          popup: "right",
          color: self.headerStyleObj.backgroundColor,
          onOpen: () =>
            (headerBtn.querySelector(".picker_wrapper").style.zIndex = 100)
        });
        let picker2 = new Picker({
          parent: textBtn,
          popup: "right",
          color: self.headerTextStyleObj.color,
          onOpen: () =>
            (textBtn.querySelector(".picker_wrapper").style.zIndex = 100)
        });
        let picker3 = new Picker({
          parent: pageBtn,
          popup: "right",
          color: self.bodyStyleObj.backgroundColor,
          onOpen: () =>
            (pageBtn.querySelector(".picker_wrapper").style.zIndex = 100)
        });
        let picker4 = new Picker({
          parent: contactBtn,
          popup: "right",
          color: self.contactTextStyleObj.color,
          onOpen: () =>
            (contactBtn.querySelector(".picker_wrapper").style.zIndex = 100)
        });

        picker1.onChange = function(color) {
          self.headerStyleObj = { backgroundColor: color.rgbaString };
        };
        picker2.onChange = function(color) {
          self.textBtnStyleObj = { backgroundColor: color.rgbaString };
          self.headerTextStyleObj = { color: color.rgbaString };
        };
        picker3.onChange = function(color) {
          self.bodyStyleObj = { backgroundColor: color.rgbaString };
        };
        picker4.onChange = function(color) {
          self.contactBtnStyleObj = { backgroundColor: color.rgbaString };
          self.contactTextStyleObj = { color: color.rgbaString };
        };
        picker1.onClose = function(color) {
          let objToSend = {
            userEmail: self.coordinatorEmail,
            color: color.rgbaString,
            type: "header",
            url: subdomain
          };
          self.$axios
            .post("/company/company-page-color", objToSend)
            .then(response => {
              if (response.status != 200 || !response.data) {
                self.screenSliderMsg =
                  "Server Error! Back-end did not save your color";
              } else {
                self.screenSliderMsg =
                  "Your Colour choice was successfully saved to our back-end";
              }
              self.sliderModalMiddleMan = true;
            });
        };
        picker2.onClose = function(color) {
          let objToSend = {
            userEmail: self.coordinatorEmail,
            color: color.rgbaString,
            type: "headerText",
            url: subdomain
          };
          self.$axios
            .post("/company/company-page-color", objToSend)
            .then(response => {
              if (response.status != 200 || !response.data) {
                self.screenSliderMsg =
                  "Server Error! Back-end did not save your color";
              } else {
                self.screenSliderMsg =
                  "Your Colour choice was successfully saved to our back-end";
              }
              self.sliderModalMiddleMan = true;
            });
        };
        picker3.onClose = function(color) {
          let objToSend = {
            userEmail: self.coordinatorEmail,
            color: color.rgbaString,
            type: "background",
            url: subdomain
          };
          self.$axios
            .post("/company/company-page-color", objToSend)
            .then(response => {
              if (response.status != 200 || !response.data) {
                self.screenSliderMsg =
                  "Server Error! Back-end did not save your color";
              } else {
                self.screenSliderMsg =
                  "Your Colour choice was successfully saved to our back-end";
              }
              self.sliderModalMiddleMan = true;
            });
        };
        picker4.onClose = function(color) {
          let objToSend = {
            userEmail: self.coordinatorEmail,
            color: color.rgbaString,
            type: "contactText",
            url: subdomain
          };
          self.$axios
            .post("/company/company-page-color", objToSend)
            .then(response => {
              if (response.status != 200 || !response.data) {
                self.screenSliderMsg =
                  "Server Error! Back-end did not save your color";
              } else {
                self.screenSliderMsg =
                  "Your Colour choice was successfully saved to our back-end";
              }
              self.sliderModalMiddleMan = true;
            });
        };
      }
    },
    smallImg(e, typeOfImg) {
      if (typeOfImg === "header") {
        this.headerImgToSendToDb = e;
      } else {
        this.profileImgToSendToDb = e;
      }
    },
    dismiss(key) {
      this[key] = false;
    },
    copyPageLink() {
      saveToClipboard(this.link, this.$root.$refs.alert);
    },
    onToggleHidePast: debounce(function(value) {
      this.$axios.post("/company/toggle-past", {
        value
      });
    }, 100)
  }
};
</script>
