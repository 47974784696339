<template>
  <div class="brand-colors-wrapper" :class="{ active: sideBar }">
    <div class="inner-container">
      <div class="colors-header">
        <h4>Customize Brand Page Colors</h4>
        <span>Click to select your color</span>
      </div>

      <div class="colors-container">
        <!-- <color-picker
          name="textColor"
          display="Page Background"
          :color="customColors.pageBG"
          @change="changeColor('pageBG', ...arguments)"
        /> -->
        <color-picker
          name="textColor"
          display="Brand Titles"
          :color="customColors.brandTitles"
          @change="changeColor('brandTitles', ...arguments)"
        />
        <color-picker
          name="textColor"
          display="Contact Info"
          :color="customColors.contactInfo"
          @change="changeColor('contactInfo', ...arguments)"
        />
        <color-picker
          name="textColor"
          display="Button Color"
          :color="customColors.buttonColor"
          @change="changeColor('buttonColor', ...arguments)"
        />
        <diyobo-button txt="Reset" @click="reset" class="reset-btn primary" />
      </div>
    </div>

    <div class="side-bar" @click="showSideBar">
      <font-awesome-icon icon="chevron-up" />
      <div class="text">Customize Brand Page Colors</div>
      <font-awesome-icon icon="chevron-up" />
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faChevronUp, faChevronLeft);

export default {
  name: "brand-colors",
  props: {
    defaultColors: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      sideBar: false,
      customColors: {
        pageBG: "var(--content-background)",
        brandTitles: "var(--text)",
        contactInfo: "var(--text)",
        buttonColor: "var(--tab-bg)"
      }
    };
  },
  computed: {
    customStyle() {
      return {
        "--custom-brand-pageBG-color": this.customColors.pageBG,
        "--custom-brand-brandTitles": this.customColors.brandTitles,
        "--custom-brand-contactInfo": this.customColors.contactInfo,
        "--custom-brand-buttonColor": this.customColors.buttonColor
      };
    }
  },
  components: {
    ColorPicker,
    FontAwesomeIcon,
    DiyoboButton
  },
  methods: {
    reset() {
      this.customColors.pageBG = "var(--content-background)";
      this.customColors.brandTitles = "var(--text)";
      this.customColors.contactInfo = "var(--text)";
      this.customColors.buttonColor = "var(--tab-bg)";
      this.$emit("changeColor", this.customColors);
    },
    changeColor(field, color) {
      this.customColors[field] = color;
      this.$emit("changeColor", this.customColors);
    },
    showSideBar() {
      this.sideBar = !this.sideBar;
    }
  },
  created() {
    if (this.defaultColors) {
      this.customColors = this.defaultColors;
    }
  }
};
</script>

<style lang="less">
.colors-container {
  display: flex;
  flex-direction: column;

  .reset-btn {
    margin-top: 8px;
  }

  .color-picker-wrapper {
    height: 40px;
    margin: 8px 0;

    button.color-btn {
      width: 100%;
      justify-content: space-between;
    }
    // margin-right: 8px;
  }
  // button {
  //   width: 100%;
  //   justify-content: space-between;
  // }
}
</style>
<style lang="less">
.brand-colors-wrapper {
  position: fixed;
  top: 50%;
  transform: translate(-88%, -50%);
  z-index: 1000;
  background: var(--content-background);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #424242;
  border-left: 0;
  padding: 16px;

  display: flex;
  padding-right: 40px;
  align-items: center;

  &.active {
    transform: translate(0%, -50%);
  }

  h4 {
    margin: 0;
    font-size: 18px;
    color: var(--text);
  }
  span {
    font-size: 12px;
  }

  .side-bar {
    transform: rotate(270deg);
    position: fixed;
    left: 44%;
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 10px;
    justify-content: center;
    cursor: pointer;

    .text {
      color: var();
    }
  }
}
</style>
